var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container", attrs: { id: "detail" } }, [
    _vm._m(0),
    _vm.productDetail
      ? _c("div", { staticClass: "detail_area mt-2" }, [
          _c("div", { staticClass: "info_area" }, [
            _c("div", { staticClass: "info_area_title mb-6" }, [
              _vm._v(_vm._s(_vm.productDetail.Name))
            ]),
            _c("div", {
              staticClass: "info_area_content",
              domProps: { innerHTML: _vm._s(_vm.productDetail.Description) }
            }),
            _c("div", { staticClass: "info_area_otherInfo mb-12" }, [
              _c("h3", { staticClass: "mb-2" }, [_vm._v("適用")]),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.productDetail.Appliance) }
              })
            ]),
            _c("div", { staticClass: "info_area_otherInfo mb-12" }, [
              _c("h3", { staticClass: "mb-2" }, [_vm._v("限制")]),
              _c("p", {
                domProps: { innerHTML: _vm._s(_vm.productDetail.Restriction) }
              })
            ])
          ]),
          _c("div", { staticClass: "pic_area" }, [
            _c("img", { attrs: { src: _vm.productDetail.ImageSrc, alt: "" } })
          ]),
          _c("div", { staticClass: "buy_area" }, [
            _vm.needShowQuantityControl
              ? _c("div", { staticClass: "buy_area_quantity" }, [
                  _c("div", {
                    staticClass: "buy_area_quantity_arrow decrease",
                    on: {
                      click: function($event) {
                        return _vm.quantityAdd("dec")
                      }
                    }
                  }),
                  _c("div", { staticClass: "buy_area_quantity_content" }, [
                    _c(
                      "div",
                      { staticClass: "buy_area_quantity_content_number" },
                      [
                        _c("input", {
                          attrs: { type: "number", min: 1, max: 999 },
                          domProps: { value: _vm.butQuantity },
                          on: {
                            input: _vm.quantityHandler,
                            blur: function($event) {
                              _vm.butQuantity < 1
                                ? (_vm.butQuantity = 1)
                                : _vm.butQuantity
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "buy_area_quantity_content_unit" },
                      [_vm._v("組兌換碼")]
                    )
                  ]),
                  _c("div", {
                    staticClass: "buy_area_quantity_arrow increase",
                    on: {
                      click: function($event) {
                        return _vm.quantityAdd("add")
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c("div", { staticClass: "buy_area_price" }, [
              _c("div", { staticClass: "price" }, [
                _c("span", [_vm._v("NT.")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.productDetail.Amount * _vm.butQuantity))
                ])
              ]),
              _vm._m(1)
            ]),
            _vm.needShowUseTypeRadio && !_vm.needShowQuantityControl
              ? _c("div", { staticClass: "buy_area_useType" }, [
                  _c("div", { staticClass: "buy_area_useType_radio" }, [
                    _c("label", { attrs: { for: "persoinal" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.useType,
                            expression: "useType"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "useType",
                          id: "persoinal",
                          value: "persoinal"
                        },
                        domProps: { checked: _vm._q(_vm.useType, "persoinal") },
                        on: {
                          change: [
                            function($event) {
                              _vm.useType = "persoinal"
                            },
                            function($event) {
                              _vm.receiverMemberId = ""
                            }
                          ]
                        }
                      }),
                      _c("span", [_vm._v("自用")])
                    ]),
                    _c("label", { attrs: { for: "gift" } }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.useType,
                            expression: "useType"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          name: "useType",
                          id: "gift",
                          value: "gift"
                        },
                        domProps: { checked: _vm._q(_vm.useType, "gift") },
                        on: {
                          change: function($event) {
                            _vm.useType = "gift"
                          }
                        }
                      }),
                      _c("span", [_vm._v("送禮")])
                    ])
                  ]),
                  _vm.useType == "gift"
                    ? _c(
                        "div",
                        { staticClass: "buy_area_useType_receiverMemberId" },
                        [
                          _c("label", { attrs: { for: "receiverMemberId" } }, [
                            _vm._v("送禮對象 ID")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: _vm.receiverMemberId,
                                expression: "receiverMemberId",
                                modifiers: { trim: true }
                              }
                            ],
                            attrs: {
                              type: "text",
                              id: "receiverMemberId",
                              placeholder: "請輸入對方帳號 ID"
                            },
                            domProps: { value: _vm.receiverMemberId },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.receiverMemberId = $event.target.value.trim()
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "buy_area_btn",
                class: { notReady: _vm.isSaleOut },
                on: {
                  click: function($event) {
                    return _vm.toBuy()
                  }
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.isSaleOut ? "已購買" : "立即購買") +
                    "\n      "
                )
              ]
            ),
            _c("div", { staticClass: "buy_area_notify" }, [
              _vm._m(2),
              _c("div", {
                staticClass: "buy_area_notify_content",
                domProps: { innerHTML: _vm._s(_vm.productDetail.Notice) }
              })
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "mt-2" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/titleIcon.svg"), alt: "" }
      }),
      _vm._v("\n    詳細資訊\n  ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "payMethods" }, [
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/cc-visa.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/master.svg"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("../../assets/占星小鋪/Vector.svg"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buy_area_notify_title" }, [
      _c("div", [_c("span")]),
      _c("h3", [_vm._v("注意事項")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }