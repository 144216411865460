<template>
  <div class="wrap" id="newMoon" v-if="todayIsNewMoon">
    <div class="header">
      <h2 class="st_title hidden-pad-down">
        <img src="@/assets/月亮日記/月亮日記標題圖片.svg" alt="" />
        關於新月祈願
      </h2>
    </div>
    <!---------------- Moonday Note-------------- -->
    <div class="newMoon">
      <div class="newMoon__header">
        <div class="newMoon__header__content">
          <div class="newMoon__header__content__title">
            {{ queryitemConstellationCN(todayIsNewMoon.Constellation) }}新月
          </div>
          <!-- <div>{{ `${moondayNoteInfo.Date} ${moondayNoteInfo.Time}` }}</div> -->
          <div>
            <span>{{
              moment(todayIsNewMoon.StartTime).format("MM/DD HH:mm")
            }}</span
            >~<span>{{ endTime }}</span>
          </div>
        </div>
        <img class="pic1" src="@/assets/月亮日記/筆記裝飾_月亮.svg" alt="" />
        <img class="pic2" src="@/assets/月亮日記/筆記裝飾星星大.svg" alt="" />
        <img class="pic3" src="@/assets/月亮日記/筆記裝飾星星小.svg" alt="" />
      </div>
      <div class="newMoon__body">
        <div class="newMoon__body__main" v-html="prayContent"></div>

        <!-- 每到天蠍新月，是否起心動念想「砍掉重練、重新開始」？
          <br />
          <br />
          許下10個「與天蠍有關」願望，更容易實現。
          <br />
          <br />
          天蠍是「毀滅、之後重生」的力量，有什麼事「已到最後關頭」、自知不改不行呢？那就放下得過且過的執念，許下願望，好好改變吧！
          <br />
          <br />
          天蠍也與「大錢」有關，舉凡遺產、投資、稅金罰款、有金錢煩惱、生意往來有變化等，都可許下理財、佈局規劃的心願。
          <br />
          <br />
          天蠍也象徵「生死、性別、性議題」，受其之苦者，也請把握機會許願，讓深沉的心魔、煩惱好好釋放吧！
          <br />
          <br />
          每個星座都可在任何新月許願，願望必須與自身有關，不能幫他人代許，一個月後再來檢驗應許多少吧！ -->
      </div>
      <div class="newMoon__notice">
        <img src="@/assets/提示燈泡.svg" alt="" />
        <div>前往月亮日記許下你的願望，八小時內記得許願喔！</div>
      </div>
      <div class="newMoon__btn" @click="toMoonday">
        <span v-if="!enabledMoonNote">解鎖月亮日記</span>
        <span v-else>前往月亮日記</span>
      </div>
    </div>

    <!-- 顯示時間還沒到 -->
    <transition name="fade">
      <Fixed_popup v-show="showTimeNotYet">
        <div class="cannot__box">
          <img class="cannot__img" src="@/assets/月亮日記/新月未到.svg" alt />
          <div class="cannot__title">祈願時間還沒到喔</div>
          <div class="cannot__subtitle">
            再等一下，等祈願時間到了，就可以許願囉
          </div>
          <div class="cannot__btn" @click="showTimeNotYet = false">返回</div>
        </div>
      </Fixed_popup>
    </transition>

    <!-- 顯示時間已過 -->
    <transition name="fade">
      <Fixed_popup v-show="showTimeOver">
        <div class="cannot__box">
          <img class="cannot__img" src="@/assets/月亮日記/新月已過.svg" alt />
          <div class="cannot__title">新月祈願已結束囉</div>
          <div class="cannot__subtitle">
            下次有相關新月祈願的活動，會再通知你喔
          </div>
          <div class="cannot__btn" @click="$router.push('/moonday-list')">
            返回月亮日記
          </div>
        </div>
      </Fixed_popup>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import Fixed_popup from "@/components/Fixed_popup.vue";
import DOMPurify from "dompurify";

export default {
  components: { Fixed_popup },

  data() {
    return {
      Constellation_CN: "",
      //顯示時間還沒到
      showTimeNotYet: false,
      //顯示時間已過
      showTimeOver: false,
      contentInfo: "",
    };
  },
  created() {
    if (!this.todayIsNewMoon) {
      this.$router.replace("/moonday-list");
    }
  },
  computed: {
    enabledMoonNote() {
      return this.$store.state.userData.EnabledMoonNote;
    },
    todayIsNewMoon() {
      return this.$store.getters.todayIsNewMoon;
    },
    prayContent() {
      let sourceText = this.todayIsNewMoon.PrayContent;
      return DOMPurify.sanitize(sourceText);
    },
    endTime() {
      //this.todayIsNewMoon.Start的八小時後
      return moment(this.todayIsNewMoon.StartTime)
        .add(8, "hours")
        .format("MM/DD HH:mm");
    },
  },
  methods: {
    moment,
    queryitemConstellationCN(constellation_en) {
      let astro = this.$Astro.find((item) => constellation_en === item.en);
      return astro.cn;
    },
    toMoonday() {
      if (this.enabledMoonNote) {
        let astro = this.$Astro.find(
          (item) => item.en === this.todayIsNewMoon.Constellation
        );
        //開始時間還沒到
        if (
          moment(this.todayIsNewMoon.StartTime)
            .subtract(8, "hours")
            .isAfter(moment(new Date()))
        ) {
          return (this.showTimeNotYet = true);
        }
        //許願時間已過
        if (
          moment(this.todayIsNewMoon.StartTime)
            .add(8, "hours")
            .isBefore(moment(new Date()))
        ) {
          return (this.showTimeOver = true);
        }

        this.$store.commit("setMoondayEdit", {
          MoonNoteID: "",
          Constellation_CN: astro.cn,
          Constellation: this.todayIsNewMoon.Constellation,
          Content: "",
          MoodContent: "",
          Date: moment(new Date()).format("YYYY-MM-DD"),
          Time: moment(new Date()).format("HH:mm"),
        });
        this.$router.push("/moonday-create");
      } else {
        let productId = this.$store.getters.productListMap["MoonNote"];
        this.$router.push(`/store/detail/${productId}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#newMoon {
  padding-top: 20px;
  width: 100%;
  max-width: 989px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include mobile {
    padding: 17px 16px 0;
  }
  .header {
    display: flex;
    margin: 42px 0;
    @include mobile {
      height: 0;
      margin: 0;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      line-height: 58px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      img {
        width: 54px;
        margin-right: 16px;
        @include mobile {
          width: 30px;
          margin-right: 5px;
        }
      }
    }
  }
  .newMoon {
    max-width: 720px; //640px
    margin: 0 auto;
    padding: 24px 40px 48px;
    box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.3), -4px -4px 10px #fff,
      -4px -4px 16px #fff;
    border-radius: 50px;
    @include mobile {
      max-width: 343px;
      padding: 12px 20px 36px;
      border-radius: 20px;
    }
    &__header {
      height: 110px;
      padding: 28px 64px 21px;
      position: relative;
      display: flex;
      @include mobile {
        font-size: 13px;
        line-height: 18px;
        height: 95px;
        padding: 21px 32px;
        &__title {
          font-size: 20px;
          line-height: 21px;
          margin-bottom: 8px;
        }
        &__mood {
          width: 28px;
          height: 28px;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      &__content {
        font-weight: 500;
        color: #868686;
        font-size: 16px;
        @include mobile {
          font-size: 13px;
        }
        &__title {
          color: $theme-color-2;
          font-size: 28px;
          line-height: 28px;
          margin-bottom: 12px;
          @include mobile {
            font-size: 13px;
            font-size: 20px;
            margin-bottom: 8px;
          }
        }
      }
      & > img {
        position: absolute;
      }
      .pic1 {
        height: 124px;
        width: 124px;
        top: 0;
        right: 0;
        @include mobile {
          height: 106px;
          width: 106px;
        }
      }
      .pic2 {
        width: 16px;
        height: 16px;
        top: 57px;
        left: 0;
      }
      .pic3 {
        width: 10px;
        height: 10px;
        bottom: 20px;
        left: 23px;
        @include mobile {
          bottom: 16px;
          left: 16px;
        }
      }
    }
    &__body {
      max-width: 560px; //480px
      padding: 28px 0 0;
      margin: 0 auto;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #868686;
      word-break: break-all;
      @include mobile {
        font-size: 16px;
        padding-top: 0;
        letter-spacing: 0.64px;
      }
      &__main {
        text-align: justify;
        @include mobile {
          margin-top: 16px;
        }
      }
    }
    &__notice {
      max-width: 560px; //480px
      height: auto;
      margin: 20px auto 0;
      padding: 26px 21px;
      border: 1px solid #d2d2d2;
      border-radius: 20px 35px 20px 35px;
      color: #868686;
      display: flex;
      align-items: flex-start;
      letter-spacing: 0.64px;
      img {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
      span {
        color: $theme-color-2;
      }
      @include mobile {
        align-items: flex-start;
        padding: 16px 12px;
        font-size: 14px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    &__btn {
      @include btn-default(230px, 56px, 20px);
      margin: 40px auto 0;
      @include mobile {
        margin-top: 36px;
        @include btn-default(180px, 40px, 14px);
      }
    }
  }
  .moondayNoteGoBack {
    margin-top: 36px;
    color: #798da5;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.cannot__box {
  z-index: 1050;
  margin: auto;
  @include rect(480px, auto, $theme-color-3, 100px);
  @include center;
  flex-direction: column;
  padding: 28px 0 40px;
  transition: 0.5s;
  @include mobile() {
    @include rect(326px, auto, $theme-color-3, 110px);
    margin: 0 24px;
  }
  .cannot__img {
    width: 300px;
    object-fit: cover;
    margin: 0 auto;
    @include mobile() {
      width: 230px;
    }
  }
  .cannot__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    margin-top: 28px;
    color: $secondary-color-1;
    @include mobile() {
      font-size: 20px;
      line-height: 24px;
      margin-top: 20px;
    }
  }
  .cannot__subtitle {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: 1.6px;
    color: #9b9b9b;
    margin-top: 16px;
    @include mobile() {
      margin-top: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 100% */
      letter-spacing: 0.56px;
    }
  }
  .cannot__btn {
    @include btn(245px, 44px, $theme-color-1, $theme-color-3, 20px);
    margin-top: 32px;
    transition: 0.2s;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    @include mobile() {
      @include btn(234px, 40px, $theme-color-1, $theme-color-3, 14px);
      margin-top: 24px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    &:hover {
      opacity: 0.7;
    }
    &:active {
      background-color: $secondary-color-1;
    }
  }
  .cannot__textBtn {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: $theme-color-1;
    margin-top: 16px;
    cursor: pointer;
    @include mobile() {
      font-size: 14px;
      line-height: 14px;
      margin-top: 20px;
    }
  }
}
</style>
