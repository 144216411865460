<template>
  <div class="container" id="detail">
    <h1 class="mt-2">
      <img src="../../assets/占星小鋪/titleIcon.svg" alt="" />
      詳細資訊
    </h1>
    <div class="detail_area mt-2" v-if="productDetail">
      <!-- 商品資訊區 -->
      <div class="info_area">
        <div class="info_area_title mb-6">{{ productDetail.Name }}</div>
        <div class="info_area_content" v-html="productDetail.Description"></div>
        <div class="info_area_otherInfo mb-12">
          <h3 class="mb-2">適用</h3>
          <p v-html="productDetail.Appliance"></p>
        </div>
        <div class="info_area_otherInfo mb-12">
          <h3 class="mb-2">限制</h3>
          <p v-html="productDetail.Restriction"></p>
        </div>
      </div>
      <!-- 中間圖片區 -->
      <div class="pic_area">
        <img :src="productDetail.ImageSrc" alt="" />
      </div>
      <!-- 購買與注意事項區 -->
      <div class="buy_area">
        <!-- 選擇數量 -->
        <div class="buy_area_quantity" v-if="needShowQuantityControl">
          <div
            class="buy_area_quantity_arrow decrease"
            @click="quantityAdd('dec')"
          ></div>
          <div class="buy_area_quantity_content">
            <div class="buy_area_quantity_content_number">
              <input
                type="number"
                :min="1"
                :max="999"
                :value="butQuantity"
                @input="quantityHandler"
                @blur="butQuantity < 1 ? (butQuantity = 1) : butQuantity"
              />
            </div>
            <div class="buy_area_quantity_content_unit">組兌換碼</div>
          </div>
          <div
            class="buy_area_quantity_arrow increase"
            @click="quantityAdd('add')"
          ></div>
        </div>
        <!-- 價格 -->
        <div class="buy_area_price">
          <div class="price">
            <span>NT.</span>
            <span>{{ productDetail.Amount * butQuantity }}</span>
          </div>
          <div class="payMethods">
            <img src="../../assets/占星小鋪/cc-visa.svg" alt="" />
            <img src="../../assets/占星小鋪/master.svg" alt="" />
            <img src="../../assets/占星小鋪/Vector.svg" alt="" />
          </div>
        </div>
        <!-- 選擇自用或送禮 -->
        <div
          class="buy_area_useType"
          v-if="needShowUseTypeRadio && !needShowQuantityControl"
        >
          <div class="buy_area_useType_radio">
            <label for="persoinal">
              <input
                type="radio"
                name="useType"
                id="persoinal"
                value="persoinal"
                v-model="useType"
                @change="receiverMemberId = ''"
              />
              <span>自用</span>
            </label>
            <label for="gift">
              <input
                type="radio"
                name="useType"
                id="gift"
                value="gift"
                v-model="useType"
              />
              <span>送禮</span>
            </label>
          </div>
          <div
            class="buy_area_useType_receiverMemberId"
            v-if="useType == 'gift'"
          >
            <label for="receiverMemberId">送禮對象 ID</label>
            <input
              type="text"
              id="receiverMemberId"
              v-model.trim="receiverMemberId"
              placeholder="請輸入對方帳號 ID"
            />
          </div>
        </div>
        <!-- 購買按鈕 -->
        <div
          class="buy_area_btn"
          @click="toBuy()"
          :class="{ notReady: isSaleOut }"
        >
          {{ isSaleOut ? "已購買" : "立即購買" }}
        </div>
        <div class="buy_area_notify">
          <div class="buy_area_notify_title">
            <div><span></span></div>
            <h3>注意事項</h3>
          </div>
          <div
            class="buy_area_notify_content"
            v-html="productDetail.Notice"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "store-detail",
  data() {
    return {
      butQuantity: 1,
      useType: "persoinal",
      receiverMemberId: "",
      productDetail: {},
    };
  },
  async created() {
    this.$store.dispatch("setLoading", true);
    await this.getProductDetail();
    setTimeout(() => {
      this.$store.dispatch("setLoading", false);
    }, 500);
    //     if (moment().isAfter("2023-06-27 06:30")) return;
    //     this.$messagePopup(
    //       `【藍新金流系統通知】`,
    //       `6/27(二) 01:00 AM ~ 06:30 AM<br>銀行信用卡系統維護提醒，請勿在維護期間付款，避免造成服務解鎖失敗！<br>
    // 詳情請參閱: <a target="_blank" href="https://www.newebpay.com/info/news/news_detail?post_data=63412d677da3bccca49a09bba5857614344d263cafbd5c107aa36e732ea8d851">藍新金流服務公告</a>`,
    //       "了解",
    //       () => {}
    //     );
  },
  computed: {
    productList() {
      return this.$store.state.productList;
    },
    isSaleOut() {
      let slefHaveBuy =
        (this.productDetail.Code == "TransitChart" &&
          this.$store.state.userData.IsUnlockLC) ||
        (this.productDetail.Code == "MoonNote" &&
          this.$store.state.userData.EnabledMoonNote);

      return slefHaveBuy && this.useType === "persoinal";
    },
    needShowQuantityControl() {
      return this.productDetail.Type == 3;
    },
    needShowUseTypeRadio() {
      return true;
    },
  },
  methods: {
    async getProductDetail() {
      try {
        let res = await this.$API.GET_ProductDetail(this.$route.params.id);
        this.productDetail = res.data.Data;
      } catch (e) {
        console.error(e);
      }
    },
    async toBuy() {
      if (this.isSaleOut) return;

      if (this.useType === "gift") {
        if (!this.receiverMemberId) {
          this.$noticeDiag("請輸入對方帳號 ID", "", "我知道了", () => {});
          return;
        } else {
          // 檢核能否送禮
          let vaildSendGift = false;
          try {
            let res = await this.$API.GET_CheckBuyGift(
              this.receiverMemberId,
              this.productDetail.ProductID
            );
            vaildSendGift = res.data.Data;
          } catch (e) {
            console.error(e);
          }
          if (!vaildSendGift) {
            this.$imageNotice({
              imageIdx: 1,
              title: "朋友已經買過囉",
              desc: `您想贈送的對象：(ID:${this.receiverMemberId}) 已經購買過，可以再從星星雜貨店另外挑選禮物喔！`,
              okText: "前往星星雜貨店",
              okEvent: () => {
                this.$router.push("/store");
              },
              cancelText: "返回當前頁面",
            });
            return;
          }
        }
      }

      let orderObj = this.$store.state.orderCreater;
      orderObj.ProductID = this.productDetail.ProductID;
      orderObj.Code = this.productDetail.Code;
      orderObj.Quantity = this.butQuantity || 1;
      orderObj.ReceiverMemberId = this.receiverMemberId
        ? this.receiverMemberId * 1 // 轉Int
        : null;
      if (this.productDetail.Type == 0 && this.useType !== "gift") {
        //判斷服務需選對象，禮物模式則不用
        this.$store.commit("updateOrderCreater", orderObj);
        this.$router.push("/store/selectTarget");
      } else {
        //判斷服務不須選對象，直接到購買流程
        orderObj.Astrolabe =
          this.$store.state.userData.AstrolabeFriendLists.find(
            (item) => item.Relationship == "R0" && item.IsBelongToMember
          );
        this.$store.commit("updateOrderCreater", orderObj);
        this.$router.push("/order/checkInfo");
      }
    },
    quantityHandler(event) {
      let value = event.target.value;
      if (value < 0) {
        this.butQuantity = 0;
      } else if (value > 999) {
        this.butQuantity = 999;
      } else {
        this.butQuantity = value;
      }
      this.$forceUpdate();
    },
    quantityAdd(type) {
      if (type === "add") {
        if (this.butQuantity < 999) {
          this.butQuantity++;
        }
      } else {
        if (this.butQuantity > 1) {
          this.butQuantity--;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";
#detail {
  padding: 0 23px;
  letter-spacing: 0.08em;
  h1 {
    font-weight: 500;
    font-size: 40px;
    line-height: 58px;
    margin-bottom: 24px;
    color: #ab956c;
    padding-left: 120px;
    display: flex;
    align-items: center;
    @include pad {
      font-size: 18px;
      padding-left: 0;
      justify-content: center;
    }
    > img {
      height: 54px;
      @include pad {
        height: 26px;
      }
    }
  }
  .detail_area {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    padding: 72px 76px;
    background: #f0f2f4;
    box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
      4px 4px 25px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    @include pad {
      flex-direction: column;
      padding: 50px 42px;
      border-radius: 80px;
    }
    .info_area {
      width: 290px;
      @include pad {
        width: 100%;
      }
      .info_area_title {
        font-weight: 700;
        font-size: 28px;
        line-height: 40px;
        color: $secondary-color-1;
        letter-spacing: 0.08em;
      }
      .info_area_content {
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
        color: #9b9b9b;
        margin-bottom: 72px;
        @include pad {
          margin-bottom: 30px;
        }
      }
      .info_area_otherInfo {
        h3 {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.08em;
          color: #9b9b9b;
        }
        p {
          font-size: 20px;
          line-height: 36px;
          font-weight: 700;
          letter-spacing: 0.08em;
          margin: 0;
          color: $secondary-color-1;
        }
      }
    }
    .pic_area {
      width: 530px;
      padding: 0 38px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include pad {
        display: none;
      }
      & > img {
        width: 100%;
      }
    }
    .buy_area {
      width: 290px;
      @include pad {
        width: 100%;
      }
      .buy_area_price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        margin-bottom: 40px;
        &::after {
          content: "";
          height: 1px;
          width: 140%;
          position: absolute;
          bottom: -15px;
          left: 0;
          background-color: $secondary-color-2;
        }
        .price {
          display: flex;
          align-items: flex-end;
          font-weight: 700;
          color: $secondary-color-1;

          & > span:nth-of-type(1) {
            font-size: 16px;
          }
          & > span:nth-of-type(2) {
            font-size: 40px;
            line-height: 1;
          }
        }
        .payMethods {
          display: flex;
          > img {
            height: 16px;
            margin-left: 6px;
          }
        }
      }
      .buy_area_btn {
        margin-top: 36px;
        @include btn-default(232px, 56px, $tw_font);
      }
      .buy_area_notify {
        margin-top: 48px;
        .buy_area_notify_title {
          display: flex;
          align-items: center;
          h3 {
            font-size: 20px;
            line-height: 36px;
            color: #9b9b9b;
            padding-left: 14px;
          }
          div {
            width: 32px;
            height: 32px;
            background: #f0f2f4;
            box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
              4px 4px 10px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            @include center;
            span {
              display: inline-block;
              height: 2px;
              width: 16px;
              background-color: $theme-color-2;
            }
          }
        }
        .buy_area_notify_content {
          margin-top: 22px;
          font-size: 16px;
          line-height: 22px;
          text-align: justify;
          color: #9b9b9b;
          font-weight: 700;
        }
      }

      //選擇數量
      .buy_area_quantity {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        &_arrow {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          font-size: 30px;
          font-weight: 500;
          background: #f0f2f4;
          @include center;
          cursor: pointer;
          box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
            4px 4px 10px rgba(0, 0, 0, 0.2);
          position: relative;
          @include mobile {
            width: 35px;
            height: 35px;
          }
          &.decrease:after,
          &.increase:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 3px;
            width: 22px;
            border-radius: 3px;
            background-color: $theme-color-2;
          }
          &.increase:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 22px;
            width: 3px;
            border-radius: 3px;
            background-color: $theme-color-2;
          }
        }
        .buy_area_quantity_content {
          display: flex;
          align-items: center;
          margin: 0 20px;
          .buy_area_quantity_content_number {
            background: #f0f2f4;
            box-shadow: 2px 2px 4px #ffffff,
              inset 2px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 20px;
            color: $theme-color-2;
            margin-right: 8px;
            text-align: center;
            input {
              border: none;
              background: none;
              color: $theme-color-2;
              text-align: center;
              width: 62px;
              height: 40px;
              font-size: 18px;
              line-height: 40px;
              font-weight: 700;
              padding: 0 16px;
              -webkit-appearance: textfield;
              @include mobile {
                width: 59px;
                height: 36px;
                font-size: 15px;
                padding: 0 12px;
              }
              &:focus {
                outline: none;
              }
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }
          .buy_area_quantity_content_unit {
            font-size: 16px;
            line-height: 36px;
            font-weight: 700;
            color: $theme-color-2;
            @include mobile {
              font-size: 15px;
            }
          }
        }
      }
      //選擇自用或送禮
      .buy_area_useType {
        display: flex;
        flex-direction: column;
        &_radio {
          display: flex;
          margin-bottom: 20px;
          label {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 28px;
            color: #1f1f20;
            cursor: pointer;
            margin-right: 24px;
            input {
              display: none;
            }
            span {
              position: relative;
              padding-left: 30px;
              &:before {
                content: "";
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background: #f0f2f4;
                box-shadow: -2px -2px 4px #ffffff, -2px -2px 4px #ffffff,
                  4px 4px 10px rgba(0, 0, 0, 0.2);
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
              }
              &:after {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $theme-color-1;
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                display: none;
              }
            }
            input:checked + span {
              &:after {
                display: block;
              }
            }
          }
        }
        //一個僅有下劃線的輸入框
        &_receiverMemberId {
          label {
            font-size: 14px;
            line-height: 16px;
            color: #868686;
            margin-top: 20px;
            margin-bottom: 8px;
          }
          input {
            border: none;
            border-bottom: 1px solid $theme-color-2;
            background: none;
            color: #212121;
            font-size: 16px;
            line-height: 28px;
            font-weight: 700;
            padding: 0;
            width: 100%;
            &::placeholder {
              color: #d9d9d9;
            }
            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
</style>
